import { IAPICredentialsRaw } from '@canalplus/oneplayer-types';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { CookieKey } from '../../../constants/cookie';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { getEncodedPassId } from '../../../helpers/user/user-helper';
import { tokenCMSSelector } from '../../../store/slices/application-selectors';
import {
  abTestingPopulationSelector,
  microEligibilitySelector,
  passTokenSelector,
  profileIdSelector,
} from '../../../store/slices/user-selectors';

export const useGetPlayerCredentials = (): IAPICredentialsRaw => {
  const [deviceId, , trackingKeyId] =
    Cookies.get(CookieKey.DeviceId)?.split(':') || [];

  return {
    abTestPopulation: String(useSelector(abTestingPopulationSelector) || ''),
    appSessionId: Cookies.get(CookieKey.SessionId),
    deviceId,
    microEligibility: useSelector(microEligibilitySelector),
    passId: getEncodedPassId(),
    passToken: useSelector(passTokenSelector),
    profileId: useSelector(profileIdSelector)?.toString(),
    tokenCMS: useInvariantSelector(tokenCMSSelector),
    trackingKeyId,
  };
};
