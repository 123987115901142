import { useVideoState } from './useVideoState';

export type UseIsVideoReadyToPlayOptions = {
  isVideoLoaded?: boolean;
  isVideoEnded?: boolean;
  isWaitingTimeBeforePlayingOver?: boolean;
};

/**
 * Hook that checks if the video is loaded and ready to play. It prefers the props
 * over the context state. If no props are provided, it uses the context state.
 * It returns true when all conditions are met:
 * - if the waiting time before playing is over
 * - if the video is loaded
 * - if the video is not ended
 * @returns {boolean} isVideoReadyToPlay
 * @example
 *   const isVideoReadyToPlay = useIsVideoReadyToPlay(); // Uses context state entirely (useful outside of Video.tsx component)
 *   const isVideoReadyToPlay = useIsVideoReadyToPlay({ isVideoLoaded: true }); // Uses provided prop and context state for the rest
 *   const isVideoReadyToPlay = useIsVideoReadyToPlay({ isVideoLoaded: true, isVideoEnded: false, isWaitingTimeBeforePlayingOver: true }); // Uses provided props entirely
 */
export const useIsVideoReadyToPlay = ({
  isWaitingTimeBeforePlayingOver: isWaitingTimeBeforePlayingOverProp,
  isVideoEnded: isVideoEndedProp,
  isVideoLoaded: isVideoLoadedProp,
}: UseIsVideoReadyToPlayOptions | undefined = {}): boolean => {
  // Context state
  const { isWaitingTimeBeforePlayingOver, isEnded, isLoaded } = useVideoState();

  return (
    (isWaitingTimeBeforePlayingOverProp ?? isWaitingTimeBeforePlayingOver) &&
    (isVideoLoadedProp ?? isLoaded) &&
    !(isVideoEndedProp ?? isEnded)
  );
};
